import React, { Component } from "react";
import { StyledTable, THEAD, THD, TBODY, TR, TD } from "../../../components/table/table";
import { DashboardTextBox } from "../components/DashboardTexBox";
import _cloneDeep from "lodash/cloneDeep";
import { green300, black200, black700 } from "../../../theme/colors";
import { Button } from "../../../components/Button";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import {getDayOrNumberSuffix} from "../../../helpers/getDayOrNumbersuffix";
import { SelectFilter } from "../components/SelectFilter";
import { transform } from "lodash";
export default class SkuTableOutlet extends Component {
  state = {
    allQuestionsAndAnswers: []
  } 


  handleSingleEdit = (question_id, value) => {
    const { allQuestionsAndAnswers } = this.state;
    allQuestionsAndAnswers[question_id].single_input = value;
    allQuestionsAndAnswers[question_id].is_edited = true;
    this.setState({
        allQuestionsAndAnswers
    })
}


  componentDidMount() {
    const { outlet_group_details } = this.props;
    const allQuestionsAndAnswers = {}
    outlet_group_details.map(question => {
      question.related_answers.map(answers => {
        allQuestionsAndAnswers[answers.id] = answers
        delete  allQuestionsAndAnswers[answers.id].multiple_images_input
        delete  allQuestionsAndAnswers[answers.id].single_image_input
      })
    })
    this.setState({ 
        allQuestionsAndAnswers 
    })
  }

    componentDidUpdate(prevProps, prevState, snapshot) {
    const { outlet_group_details = [] } = this.props;
        if(prevProps.outlet_group_details !== outlet_group_details){
      const allQuestionsAndAnswers = {}
            outlet_group_details.map(question => {
                question.related_answers.map(answers => {
                    allQuestionsAndAnswers[answers.id] = answers
                    delete  allQuestionsAndAnswers[answers.id].multiple_images_input
                    delete  allQuestionsAndAnswers[answers.id].single_image_input
        })
            })
            this.setState({
                allQuestionsAndAnswers
            })
    }
  }

  handleSave = () => {
    const { allQuestionsAndAnswers } = this.state;
    const { on_save } = this.props;
    const skuRequestBody = {
      object_type: "answers",
      data_to_create: null,
      data_to_update: allQuestionsAndAnswers
    }
    on_save(skuRequestBody);
  
    // Optionally, update the state to reflect the changes in the UI
    this.setState({
      allQuestionsAndAnswers: { ...allQuestionsAndAnswers }
    });
  };
  

  handleMultipleEdit = (question_id, selectedOptions) => {
    const { allQuestionsAndAnswers } = this.state;
  
    // Convert selected options from format { value, label } to just values
    const updatedAnswers = selectedOptions && selectedOptions.length > 0
    ? selectedOptions.map(option => option.value)
    : [];
  
    // Update the multiple_answers_input array for the question
    allQuestionsAndAnswers[question_id].multiple_answers_input = updatedAnswers;
    allQuestionsAndAnswers[question_id].is_edited = true;
  
    // Update the state to trigger a re-render
    this.setState({
      allQuestionsAndAnswers
    });
  };

  render() {
    const { sku_products = [], outlet_group_details = [], disabled, requestInProcess } = this.props;
    const { allQuestionsAndAnswers } = this.state;
    console.log('outlet_group_details',outlet_group_details);

    return (
      <>
        <StyledTable>
          <THEAD>
                    <THD style={{position:'sticky',left:'-30px',zIndex:1,background:'white'}}> Question</THD>
            {
              sku_products.map(product =>
                                <THD>{product.name.toLowerCase().split(' ').map(x=>x[0]?.toUpperCase()+x.slice(1)).join(' ')}</THD>
              )
            }
          </THEAD>
          <TBODY>
            {outlet_group_details.map((question, upperIndex) => {
              const { related_answers = [] } = question;
              return (
                <>
                <TR>
                  <div style={{position:'sticky',left:'-30px',zIndex:1,background:'white'}}>
                    <TD>{question.name}</TD>
                  </div>
                  {
                    sku_products.map((product) => {
                      let isDone = false;

                      return (
                        <>
                          {
                            related_answers.map((answer, index) => {

                              const { sku_product = '', answer_type = 'text' } = answer;
                              if (answer.sku_product === product.id) {
                                isDone = true;
                                let previous_answers = related_answers.previous_answers || {}
                                const actual_answer = answer.sku_product === product.id ? allQuestionsAndAnswers[answer.id]?.single_input : null;

                                // Create the multipleDetails array for multiple_choice
                                const multipleDetails = [];

                                if (answer.answer_type === "multiple_choice") {
                                        const validationCondition = Array.isArray(answer.sku_product_validations)
                                        ? answer.sku_product_validations.join(';')  // Join if it's already an array
                                        : answer.sku_product_validations || '';      // Fall back to an empty string if undefined

                                    // Split the string by semicolons to create individual options
                                    validationCondition.split(";").forEach(item => {
                                        multipleDetails.push({ label: item, value: item });
                                    });
                                }
                                  
                                  // Filter the options to exclude the ones already selected in `multiple_answers_input`
                                  const filteredOptions = multipleDetails.filter(option => 
                                    !answer.multiple_answers_input.includes(option.value)  // Exclude selected options
                                  );
                                  
                                  
                                return (
                                  <TD key={index} data-tip data-for={`${answer.sku_product}-tip-${index}-${upperIndex}`} style={{overflow: "visible", border: "None"}}>

                                    {/* Conditional rendering based on answer_type */}
                                    {answer_type === 'multiple_choice' ? (
                                      <SelectFilter
                                        props={{
                                          value: answer.multiple_answers_input.map(item => ({ value: item, label: item })), // Display selected options (if any)
                                          options: filteredOptions, // Options for the dropdown
                                          onChange: (selectedOptions) => this.handleMultipleEdit(answer.id, selectedOptions),
                                          border: `1px solid ${black200}`,
                                          placeholderColor: black700,
                                          isMulti: true,
                                          width: "165px", 
                                          customStyles: {
                                            borderRadius: "2px",
                                            background: black200,
                                            
                                          },
                                        }}
                                        noOptionMessage="No data available"
                                      />    
                                    ) : (
                                      <DashboardTextBox
                                        isSKU
                                        isSearchBox={true}
                                        boxWidth={"175px"}
                                        boxHeight="40px"
                                        type="text"

                                        placeholder={`Enter ${answer_type}`}
                                        // value={answer.sku_product === product.id ? answer.single_input : null}
                                        value={actual_answer}
                                        disabled={!disabled}
                                        onChange={
                                            (e) => {
                                                answer.sku_product === product.id ? this.handleSingleEdit(answer.id, e.target.value) : console.log("TBD")
                                            }
                                        }
                                      />
                                    )}

                                    {(actual_answer && actual_answer.length > 10) && (
                                                                              <ReactTooltip id={`${answer.sku_product}-tip-${index}-${upperIndex}`} >
                                        {actual_answer}
                                      </ReactTooltip>
                                    )}


                                    {Object.keys(previous_answers).length > 0 &&
                                        (<>
                                            <InfoIcon data-tip data-for={`tooltip-${answer.sku_product}`} />
                                                <ReactTooltip
                                                    id={`tooltip-${answer.sku_product}`}
                                                    place={"bottom"}
                                                    type={"dark"}
                                                                                  >
                                                {Object.keys(previous_answers).map((key) => (
                                                    <>
                                                        <div>
                                                            {getDayOrNumberSuffix(key)} Prev: &nbsp;&nbsp;
                                                            {previous_answers[key].single_input}
                                                            <p>{previous_answers[key].sub_project_name}</p>
                                                        </div>
                                                    </>
                                                ))}
                                                </ReactTooltip>
                                        </>)
                                    }

                                  </TD>
                            )
                        }
                              if (related_answers.length - 1 === index && !isDone) {
                                return (
                                    <TD>N/A</TD>
                                )
                              }
                            })
                          }
                        </>
                        )
                    })
                  }
                </TR>
                </>
              )
            })}
          </TBODY>

          {!disabled && (
            <Button
              size="medium"
              type="submit"
              bgColor={green300}
              isLoading={requestInProcess}
              onClick={this.handleSave}
              margin={"15px"}
            >
              Save and Next
            </Button>
          )}
                    
        </StyledTable>
                
                

      </>
        )
  }
}
