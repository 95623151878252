import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BackArrow from "./arrow_back_ios.png";
import LocalWhite from "./local_activity_white.svg";
import { useHistory, useLocation } from 'react-router-dom';
import DateRangeIcon from "./date_range.svg";
import InfoICon from "./info.svg";
import EventAvailableIcon from "./event_available.svg";
import ProgramLogo from "./programLogo.svg"
import DownloadIcon from "./group_168.svg"; // Assuming you have a download icon image
import { Amount, Container, DesktopMessage, Details, Program, ProgramText } from './Earnings';
// import axios from 'axios';
import { saveAs } from 'file-saver';
import getMobileViewInvoices from '../../../actions/creation/getInvoices';
import createAutoAuthentication from './createAuthentication';
import JSEncrypt from "node-jsencrypt";
import { PUBLIC_RSA_KEY } from '../../../../src/app-config'
import Loader from '../../../components/Loader'

// Function to download PDF
const downloadPDF = async (utr) => {
  try {
    const response = await fetch(utr);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    saveAs(blob, `${utr}.pdf`);
  } catch (error) {
    console.error('Error downloading the PDF', error);
  }
};

// Styled components
export const Card = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 5px 5px #2E487B;
  height: auto; /* Changed to auto to accommodate flexible content */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between; /* Space items evenly */
  position: relative;
`;

const DownloadButton = styled.img`
  align-self: flex-end; /* Aligns the button to the right */
  width: 50px; /* Adjusted size for responsiveness */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
  margin-top: 10px; /* Space between button and content */
  
  @media (max-width: 600px) {
    width: 30%; /* Smaller size for mobile */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 200px;
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-CA'); // 'en-CA' formats as "YYYY-MM-DD"
};

const Invoices = () => {
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFoundMessage, setNotFoundMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const req = location.pathname.split('slug')[1];

  useEffect(() => {
    const autoAuthentication = async () => {
      try {
        let existingToken = localStorage.getItem('token');

        // If Local Storage is Empty or NULL
        if(existingToken === null || existingToken === "null"|| existingToken === '') {
            const now = new Date();
            const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}`;
            const generatedToken = formattedDate;  
            existingToken = encrypt_password(generatedToken);
        }
        
        localStorage.setItem('token', existingToken);

        // API Integration
        const response = await createAutoAuthentication(req, existingToken);

        if (response && response.results) {
          setIsAuthenticated(true); 
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error fetching requests:", error); 
      } finally {
        setIsLoading(false);
      }
    };

    autoAuthentication();
  }, []);

  useEffect(() => {
    const url = window.location.pathname;
    const req = url.split('slug')[1];

    getMobileViewInvoices(req)
      .then((response) => {
        const { results } = response;
        if (results && results.length > 0) {
          setInvoiceDetails(results);
        } else {
          setNotFoundMessage('No invoice found.');
        }
        // setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setNotFoundMessage('Error fetching invoices.');
        // setIsLoading(false);
      });
  }, []);

  const handleBackClick = () => {
    history.goBack();
  };

  const encrypt_password = (string_to_encrypt) => {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_RSA_KEY);
    return encrypt.encrypt(string_to_encrypt);
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <Container>
      {isDesktop ? (
        <DesktopMessage>Please switch to mobile view for a better experience</DesktopMessage>
      ) : isAuthenticated ? (
        <>
          <h3 style={{ color: "#2E487B", marginLeft: "5px", display: "flex", alignItems: "center" }}>
            <img src={BackArrow} alt="Back" style={{ marginRight: "15px" }} onClick={handleBackClick} />
            Invoice
          </h3>
          {notFoundMessage ? (
            <ErrorMessage>{notFoundMessage}</ErrorMessage>
          ) : (
            <>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                invoiceDetails.map((details) => (
                  <React.Fragment key={details.id}>
                    {/* <Program>
                      <ProgramText>
                        <img src={LocalWhite} alt="Program" style={{ marginRight: "15px" }} />
                        Program: {details.program}
                      </ProgramText>
                    </Program> */}
                    <Card>
                      <Amount>₹ {details.amount_paid}</Amount>
                      <Details>
                      <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                          <img src={ProgramLogo} style={{ marginRight: "15px" }} />
                         Prorgam: {details.program}
                        </p>
                        <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                          <img src={InfoICon} style={{ marginRight: "15px" }} />
                          Invoice Number: {details.invoice_no}
                        </p>
                        <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                          <img src={EventAvailableIcon} style={{ marginRight: "15px" }} />
                          Payment Date: {formatDate(details.created)}
                        </p>
                        <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                          <img src={DateRangeIcon} style={{ marginRight: "15px" }} />
                          {details.program} {details.sub_project_name}
                        </p>
                      </Details>
                      <DownloadButton
                        src={DownloadIcon}
                        alt="Download"
                        onClick={() => downloadPDF(details.file)}
                      />
                    </Card>
                  </React.Fragment>
                ))
              )}
            </>
          )}
        </>
      ) : (
        <DesktopMessage>
          Seems like you have opened the forwarded link.
          <p>The link is not Accessible. Please contact Retail Scan Team.</p></DesktopMessage>
      )}
    </Container>
  );
};

export default Invoices;
